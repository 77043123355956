import imageUrlBuilder from "@sanity/image-url";
import sanity from "../plugins/sanity";

/**
 * Used to build the source url for Sanity images
 *
 * @param image Sanity image object
 */
export function urlFor(image: any) {
  const builder = imageUrlBuilder(sanity);
  return builder.image(image);
}

/**
 * Used to transform dash-case to camelCase strings
 * Courtesy of: https://stackoverflow.com/a/2970667
 */
export function camelize(str) {
  return str.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

/**
 * Used to capitalize first letter of strings
 * Courtesy of: https://stackoverflow.com/a/1026087
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Transforms phosporId to component name
 */
export function toPhosphorComponentName(string) {
  return capitalizeFirstLetter(camelize(string));
}
