import sanityClient from "@sanity/client";
import getConfig from "next/config";
import Image from "next/image";
import { urlFor } from "../lib/generic";

const { serverRuntimeConfig } = getConfig();

export default sanityClient({
  projectId: "yyofrhyl",
  dataset: serverRuntimeConfig.NEXT_SANITY_DATASET ?? "production",
  useCdn: false,
  apiVersion: "2021-06-07",
  token: serverRuntimeConfig.SANITY_API_TOKEN,
});

export const serializers = {
  marks: {
    tag: (props) => {
      var style = {
        background: "",
        text: "",
      };
      switch (props.mark.color) {
        case "sky":
          style = {
            background: "bg-sky-100",
            text: "text-sky-800",
          };
          break;
      }
      return (
        <span
          className={`${style.text} ${style.background} w-min flex place-content-center font-medium whitespace-nowrap text-sm rounded py-1 px-2`}
        >
          {props.children}
        </span>
      );
    },
    link: (props) => {
      return (
        <a
          href={props.mark.url}
          title={props.mark.title}
          target={props.mark.isExternal ? "_blank" : "_self"}
          rel={`noreferrer`}
          className={`cursor-pointer underline`}
        >
          {props.children}
        </a>
      );
    },
    image: (props) => {
      const Container = props.mark.link
        ? ({ children, className }) => (
            <a
              href={props.mark.link.url}
              title={`${props.mark.link.title}`}
              target={props.mark.link.isExternal ? "_blank" : "_self"}
              rel={`noreferrer`}
              className={className}
            >
              {children}
            </a>
          )
        : ({ children, className }) => (
            <span className={className}>{children}</span>
          );

      return (
        <Container className={`inline-block align-middle h-[1rem]`}>
          <span className={`relative flex h-[1rem] w-[40px]`}>
            <Image
              alt={props.mark.alt}
              src={urlFor(props.mark.image).url()}
              layout={`fill`}
              objectFit={`contain`}
            />
          </span>
        </Container>
      );
    },
  },
  types: {
    block: (props) => {
      return <p>{props.children}</p>;
    },
  },
};
