import groq from "groq";
import { appWithTranslation } from "next-i18next";
import Head from "next/head";
import { useEffect } from "react";
import "tailwindcss/tailwind.css";
import sanity from "../plugins/sanity";
import "../styles/globals.css";

function SemWebsite({ Component, props, pageProps }) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const loadTagManager = async () => {
      // GoogleTM config
      const TagManager = (await import("react-gtm-module")).default;
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        auth: process.env.NEXT_GTM_AUTH,
        preview: process.env.NEXT_GTM_PREVIEW,
      });
    };
    loadTagManager();
  }, []);

  return getLayout(
    <>
      <Head>
        {/* iPhone notch padding override*/}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />

        <>
          <meta
            name="theme-color"
            content="#F3F4F6"
            media="(prefers-color-scheme: light)"
          />
          <meta
            name="theme-color"
            content="#374151"
            media="(prefers-color-scheme: dark)"
          />
        </>
      </Head>

      <Component {...pageProps} />
    </>,
    props
  );
}

SemWebsite.getInitialProps = async ({ Component, ctx }) => {
  const request = groq`{
    // Find element that is of type header, of current language and is not a draft
    "header": {
      "links": *[_type == "globalContent.header" && __i18n_lang == "${ctx.locale}" && !(_id in path('drafts.**'))][0]{
        _id,
        desktop,
        mobile,
        distinct
      },
    },

    // Find evey element that is of type social, of current language and is not a draft
    "footer": {
      "socials": *[_type == "globalContent.socials" && __i18n_lang == "${ctx.locale}" && !(_id in path('drafts.**'))][0]{
        _id,
        list,
      },
      "legal": *[_type == "legal.page" && __i18n_lang == "${ctx.locale}" && !(_id in path('drafts.**'))] {
        _id,
        link,
      },
    }
  }`;
  const query = await sanity.fetch(request);

  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    props: {
      header: query.header,
      footer: query.footer,
    },
    pageProps,
  };
};

// @ts-ignore
export default appWithTranslation(SemWebsite);
